import React, { Component } from "react";
import Filter, { INode, getChildren, getHistory } from "./ColumnGallery";
import {
	GlobalFilterActions,
	Filters,
	IFilter,
} from "../../repos/GlobalFilterRepo";
import { Row, Col, Card, Breadcrumb, Button, Alert } from "antd";
import {
	FolderOutlined,
	SubnodeOutlined,
	FolderOpenOutlined,
	LoadingOutlined,
	CheckOutlined,
	ExclamationOutlined,
	CaretUpFilled,
	CaretDownFilled,
} from "@ant-design/icons";
import _ from "lodash";

export default class extends Component<any, any> {
	// baseCollection: Array<INode<any>>
	filterRepoCollection: Filters;
	constructor(props: any) {
		super(props);
		this.renderChild = this.renderChild.bind(this);
		this.onChange = this.onChange.bind(this);
		// this.baseCollection = this.props.GlobalFilterRepo[0].filter((e: INode<any>) => e.PiD == 0)
		this.filterRepoCollection = this.props.GlobalFilterRepo;
		this.state = {
			isCollapsed: false,
			isExportArtwork: false,
		};
	}

	componentDidMount() {
		this.props.syncFilters();
	}

	renderChild(node: INode<any>, repo: IFilter<Array<INode<any>>>) {
		const selected = _.includes(repo.active, node);
		const childCount = getChildren(node, repo.filterDataRepo).length;
		// const selected = false;

		// if()

		return (
			<Row className={`${selected ? "selected" : ""} item`} align='middle'>
				<Col flex='20px'>
					{" "}
					{node.isLeaf ? (
						<SubnodeOutlined />
					) : selected ? (
						<FolderOpenOutlined />
					) : (
						<FolderOutlined />
					)}
				</Col>
				<Col flex='auto' order=''>
					{node.name}
				</Col>
				{childCount && childCount > 0 ? (
					<Col className='filter-panel-filter-item'>{childCount}</Col>
				) : (
					<div></div>
				)}
			</Row>
		);
	}

	onChange(node: INode<any>, repo: IFilter<Array<INode<any>>>) {
		console.log(node)
		const newReppCollection = this.props.GlobalFilterRepo.map(
			(_filter: IFilter<Array<INode<any>>>) => {
				if (_filter.id === repo.id)
					_filter = {
						..._filter,
						active: getHistory(node, repo.filterDataRepo).reverse(),
						// active: _.(
						// 	_filter.active,
						// 	getHistory(node, repo.filterDataRepo).reverse(),
						// ),
						// active: [..._filter.active, ...getHistory(node, repo.filterDataRepo).reverse()],
					};
				return _filter;
			}
		);

		this.props.setFilter(newReppCollection);
		if (repo.active.find((node) => node.id === 15)) {
			// console.log(node)
			this.setState({
				isExportArtwork: true,
			});
		} else if (
			getHistory(node, repo.filterDataRepo).reverse()[0].name === "Company[s]"
		) {
			this.setState({
				isExportArtwork: false,
			});
		}
	}

	toggleCollapsed() {
		this.setState({
			isCollapsed: !this.state.isCollapsed,
		});
	}

	render() {
		return (
			<Col>
				{this.state.isExportArtwork && (
					<div style={{ marginBottom: 10 }}>
						<Alert
							message='BDM selection is a must when creating a "Lion Brewery - Export" artwork.'
							type='info'
							showIcon
						/>
					</div>
				)}
				{this.props.GlobalFilterRepo && this.props.GlobalFilterRepo.length > 0 ? (
					this.props.GlobalFilterRepo.map((_filter: IFilter<Array<INode<any>>>) => (
						<Row key={_filter.id}>
							<Card
								style={{ marginBottom: 10, width: "100%" }}
								className='column-gallery-container'>
								<Col style={{ marginBottom: 10 }}>
									<Row justify='space-between'>
										<Col flex='auto'>
											<Row align='middle'>
												<Col flex='20px'>
													{_filter.active &&
														_filter.active.length > 1 &&
														_filter.active[_filter.active.length - 1].isLeaf ? (
														<CheckOutlined style={{ fontSize: 13, color: "#52c41a" }} />
													) : (
														<ExclamationOutlined style={{ fontSize: 13, color: "#ff4d4f" }} />
													)}
												</Col>
												<Breadcrumb>
													{_filter.active.map((node: INode<any>) => (
														<Breadcrumb.Item>{node.name}</Breadcrumb.Item>
													))}
												</Breadcrumb>
											</Row>
										</Col>
										{/* <Col>
                                            <Button type="link" onClick={this.toggleCollapsed.bind(this)}>
                                                {
                                                    this.state.isCollapsed ? <CaretUpFilled /> : <CaretDownFilled />
                                                }
                                            </Button>
                                        </Col> */}
									</Row>
									<Row>
										<Filter
											rootCollection={_filter.filterDataRepo.filter(
												(e: INode<any>) => e.PiD == 0
											)}
											onSelect={(node: INode<any>) => this.onChange(node, _filter)}
											getChildren={(node: INode<any>) =>
												getChildren(node, _filter.filterDataRepo)
											}
											renderChild={(node: INode<any>) => this.renderChild(node, _filter)}
										/>
									</Row>
								</Col>
							</Card>
						</Row>
					))
				) : (
					<LoadingOutlined />
				)}
			</Col>
		);
	}
}
